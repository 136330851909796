// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_naasInfoPlaceholderRoot__c2jgN {\n  display: flex;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  border-radius: 6px;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/InfoPlaceholder/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":[".naasInfoPlaceholderRoot {\n  display: flex;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  border-radius: 6px;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"naasInfoPlaceholderRoot": "styles_naasInfoPlaceholderRoot__c2jgN"
};
export default ___CSS_LOADER_EXPORT___;
