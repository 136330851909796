import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner, Table, TextInput } from "@skyportal/ui-kit";
import ChartBox from "layouts/ChartBox";
import * as contractApi from "api/organizations";
import { RequestStatus } from "types/common.types";
import { getClientListTable, useClientListFetch } from "./useClientListFetch";
import useClientListTableConfig from "./useClientListTableConfig";
import { contractSelectors } from "store/contracts";
import { networksSelectors } from "store/networks";
import PaginationBox from "components/PaginationBox";
import CardFallback from "components/CardFallback";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";

import styles from "./styles.module.css";

export interface ClientListProps {}

const ClientList: React.FC<ClientListProps> = () => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation("clientList");
  const { networkListRequest } = useSelector(networksSelectors.getNetworkSlice);
  const [store, dispatch] = useClientListFetch();
  const { list, pageCount, total } = getClientListTable(store);
  const columnsConfig = useClientListTableConfig();

  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  const handleFilterChange = useCallback(
    (event) => dispatch({ type: "SET_CLIENT_LIST_FILTER", payload: event.target.value }),
    [dispatch]
  );
  const handleSortFieldChange = useCallback(
    (field) => dispatch({ type: "SET_CLIENT_LIST_SORT_FIELD", payload: field }),
    [dispatch]
  );
  const handlePerPageChange = useCallback(
    (value) => dispatch({ type: "SET_CLIENT_LIST_PER_PAGE", payload: value }),
    [dispatch]
  );
  const handlePageChange = useCallback(
    (value) => dispatch({ type: "SET_CLIENT_LIST_CURRENT_PAGE", payload: value }),
    [dispatch]
  );

  const reloadData = useCallback(() => {
    if (organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkClientList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:clientListRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });
    }
  }, [dispatch, networkId, organizationId, reduxDispatch]);

  return (
    <>
      <ChartBox showNoDataPlaceholder={store.isDataEmpty}>
        {(networkListRequest === RequestStatus.PENDING || store.requestStatus === RequestStatus.PENDING) && (
          <Spinner show />
        )}
        {store.requestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.naasClientFilterBlock}>
              <TextInput
                placeholder={t("clientListFilterPlaceholder")}
                onChange={handleFilterChange}
                data-testid="clientListFilter"
              />
              <span data-testid="clientListCounter">{t("clientListNumberOfClients", { count: total })}</span>
            </div>
            <div className={styles.naasClientTableBlock} data-testid="clientListTable">
              <Table
                dataSource={list}
                rowKey="networkClient"
                columns={columnsConfig}
                sortField={store.sortField}
                sortOrder={store.sortOrder}
                onSortFieldChange={handleSortFieldChange}
              />
            </div>
            <PaginationBox
              perPage={store.perPage}
              currentPage={store.currentPage}
              pageCount={pageCount}
              onPerPageChange={handlePerPageChange}
              onChangePage={handlePageChange}
            />
          </>
        )}
        {networkListRequest !== RequestStatus.PENDING && store.requestStatus === RequestStatus.FAILURE && (
          <CardFallback onReload={reloadData} />
        )}
      </ChartBox>
    </>
  );
};

export default React.memo(ClientList);
