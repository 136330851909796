import React from "react";
import { useTranslation } from "react-i18next";
import ContractDropdown from "containers/ContractDropdown";
import NetworksDropdown from "containers/NetworksDropdown";
import Breadcrumbs from "components/Breadcrumbs";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  path: string;
}

const pageTitleMap: Record<string, string> = {
  "/naas": "Network",
  "/naas/clients": "Clients",
  "/naas/devices": "Devices",
};

const PageHeader: React.FC<PageHeaderProps> = ({ path }) => {
  const { t } = useTranslation("naas");

  return (
    <header className={styles.root}>
      <div className={styles.titleBlock}>
        <h1 className={styles.title}>{t(pageTitleMap[path])}</h1>
      </div>
      <div className={styles.naasSecondaryBlock}>
        <div className={styles.naasDropdownsBlock}>
          <ContractDropdown className={styles.dropdown} />
          <NetworksDropdown className={styles.dropdown} />
        </div>
        <Breadcrumbs />
      </div>
    </header>
  );
};

export default PageHeader;
