import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "pages/HomePage";
import ClientListPage from "pages/ClientListPage";
import DeviceListPage from "pages/DeviceListPage";

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/naas/clients" exact component={ClientListPage} />
      <Route path="/naas/devices" component={DeviceListPage} />
      <Route path="/naas" component={HomePage} />
      <Route path="*">
        <Redirect to="/naas" />
      </Route>
    </Switch>
  );
};

export default AppRouter;
