// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__Cfndt {\n  padding-bottom: var(--padding-medium);\n}\n\n.styles_rootDetailed__G9Vrj {\n  padding-bottom: 0;\n}\n\n.styles_title__N6O90 {\n  display: inline-block;\n  font-weight: 300;\n}\n\n.styles_organizationName__uBsyZ {\n  font-weight: 500;\n}\n\n.styles_actionsBlock__Wuo2K {\n  display: flex;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.styles_titlePreview__q-ySe:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/NetworkListItem/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".root {\n  padding-bottom: var(--padding-medium);\n}\n\n.rootDetailed {\n  padding-bottom: 0;\n}\n\n.title {\n  display: inline-block;\n  font-weight: 300;\n}\n\n.organizationName {\n  font-weight: 500;\n}\n\n.actionsBlock {\n  display: flex;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.titlePreview:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__Cfndt",
	"rootDetailed": "styles_rootDetailed__G9Vrj",
	"title": "styles_title__N6O90",
	"organizationName": "styles_organizationName__uBsyZ",
	"actionsBlock": "styles_actionsBlock__Wuo2K",
	"titlePreview": "styles_titlePreview__q-ySe"
};
export default ___CSS_LOADER_EXPORT___;
