// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__f02Bu {\n  display: flex;\n  -webkit-column-gap: 16px;\n          column-gap: 16px;\n}\n\n.styles_naasSubtitle__WoI2W {\n  margin: 24px 0 0;\n  font-size: 24px;\n}\n\n.styles_naasSubtitleSmallText__55nUz {\n  font-size: 16px;\n  font-weight: 300;\n}\n\n@media (max-width: 767px) {\n  .styles_root__f02Bu {\n    flex-direction: column;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/containers/WirelessStatistics/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAgB;UAAhB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".root {\n  display: flex;\n  column-gap: 16px;\n}\n\n.naasSubtitle {\n  margin: 24px 0 0;\n  font-size: 24px;\n}\n\n.naasSubtitleSmallText {\n  font-size: 16px;\n  font-weight: 300;\n}\n\n@media (max-width: 767px) {\n  .root {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__f02Bu",
	"naasSubtitle": "styles_naasSubtitle__WoI2W",
	"naasSubtitleSmallText": "styles_naasSubtitleSmallText__55nUz"
};
export default ___CSS_LOADER_EXPORT___;
