// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__jB4Cv {\n  position: relative;\n  margin-top: var(--padding-small);\n  margin-right: var(--padding-small);\n}\n\n.styles_statusIndicator__e9SQy {\n  width: 30px;\n  height: 10px;\n  box-sizing: border-box;\n  border: 1px solid #f4f4f4;\n}\n\n.styles_statusIndicator__e9SQy.styles_--success__4IEgZ {\n  background-color: var(--color-pop-green);\n}\n\n.styles_statusIndicator__e9SQy.styles_--warning__v6F4R {\n  background-color: #e59a7f;\n}\n\n.styles_statusIndicator__e9SQy.styles_--danger__qKEps {\n  background-color: var(--color-pop-red);\n}\n\n.styles_channelLabel__mcVXp {\n  display: flex;\n  width: 30px;\n  height: 10px;\n  align-items: center;\n  justify-content: center;\n}\n\n.styles_channelLabelText__roclB.SkyPortalText.--inherit {\n  color: rgba(0, 0, 0, 0.45);\n  font-size: 9px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WirelessTrafficChannelIndicator/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB","sourcesContent":[".root {\n  position: relative;\n  margin-top: var(--padding-small);\n  margin-right: var(--padding-small);\n}\n\n.statusIndicator {\n  width: 30px;\n  height: 10px;\n  box-sizing: border-box;\n  border: 1px solid #f4f4f4;\n}\n\n.statusIndicator.--success {\n  background-color: var(--color-pop-green);\n}\n\n.statusIndicator.--warning {\n  background-color: #e59a7f;\n}\n\n.statusIndicator.--danger {\n  background-color: var(--color-pop-red);\n}\n\n.channelLabel {\n  display: flex;\n  width: 30px;\n  height: 10px;\n  align-items: center;\n  justify-content: center;\n}\n\n.channelLabelText:global(.SkyPortalText.--inherit) {\n  color: rgba(0, 0, 0, 0.45);\n  font-size: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__jB4Cv",
	"statusIndicator": "styles_statusIndicator__e9SQy",
	"--success": "styles_--success__4IEgZ",
	"--warning": "styles_--warning__v6F4R",
	"--danger": "styles_--danger__qKEps",
	"channelLabel": "styles_channelLabel__mcVXp",
	"channelLabelText": "styles_channelLabelText__roclB"
};
export default ___CSS_LOADER_EXPORT___;
