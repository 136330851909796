// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__Y-xka {\n  padding: var(--padding-small);\n}\n\n.styles_statusContainer__H4Gir {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.styles_text__Av-jL.SkyPortalText.--inherit {\n  color: rgba(0, 0, 0, 0.5);\n  font-size: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WirelessTrafficChannel/styles.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".root {\n  padding: var(--padding-small);\n}\n\n.statusContainer {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.text:global(.SkyPortalText.--inherit) {\n  color: rgba(0, 0, 0, 0.5);\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__Y-xka",
	"statusContainer": "styles_statusContainer__H4Gir",
	"text": "styles_text__Av-jL"
};
export default ___CSS_LOADER_EXPORT___;
