import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Table } from "@skyportal/ui-kit";
import * as contractApi from "api/organizations";
import useFirewallDataTableConfig from "containers/DeviceList/hooks/useFirewallDataTableConfig";
import { DeviceListAction, DeviceListStore } from "containers/DeviceList/deviceListStore/deviceListStore.types";
import { networksSelectors } from "store/networks";
import { contractSelectors } from "store/contracts";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";
import ChartBox from "layouts/ChartBox";
import CardFallback from "components/CardFallback";
import { RequestStatus } from "types/common.types";
import { NetworkFirewallModel } from "api/organizations.types";

import styles from "../../styles.module.css";

export interface FirewallCardProps {
  firewallData: DeviceListStore["firewall"];
  dispatch: React.Dispatch<DeviceListAction>;
}

const FirewallCard: React.FC<FirewallCardProps> = ({ firewallData, dispatch }) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation("deviceList");
  const columnsConfig = useFirewallDataTableConfig();
  const firewallTableData = useMemo(
    () => (Object.keys(firewallData.device as NetworkFirewallModel).length ? [firewallData.device] : []),
    [firewallData.device]
  );

  const networkListRequest = useSelector(networksSelectors.getNetworkListRequestStatus);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  const reloadData = useCallback(() => {
    if (organizationId && networkId) {
      dispatch({ type: "FIREWALL_DATA_REQUEST" });
      contractApi
        .getNetworkFirewallData(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_DATA_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_DATA_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallDataRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });
    }
  }, [dispatch, networkId, organizationId, reduxDispatch]);

  return (
    <>
      <ChartBox title={t("firewallDevicesTab")} contentMinHeight={100} data-testid="firewallDeviceCard">
        {(firewallData.requestStatus === RequestStatus.PENDING || networkListRequest === RequestStatus.PENDING) && (
          <Spinner show />
        )}
        {firewallData.requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.naasDeviceTableBlock} data-testid="firewallDeviceTable">
            <Table dataSource={firewallTableData} rowKey="networkFirewallData" columns={columnsConfig} />
          </div>
        )}
        {networkListRequest !== RequestStatus.PENDING && firewallData.requestStatus === RequestStatus.FAILURE && (
          <CardFallback onReload={reloadData} />
        )}
      </ChartBox>
    </>
  );
};

export default React.memo(FirewallCard);
