import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@skyportal/ui-kit";
import { DeviceListAction, DeviceListStore } from "containers/DeviceList/deviceListStore/deviceListStore.types";
import PaginationBox from "components/PaginationBox";
import { SMALL_PAGINATION_STEPS } from "utils/table";
import useFirewallRules1ManyNATTableConfig from "containers/DeviceList/hooks/useFirewallRules1ManyNATTableConfig";
import { getFirewallRules1ManyNATTable } from "containers/DeviceList/deviceListStore/deviceListStore.selectors";

import styles from "../../styles.module.css";

export interface NAT1ManyRuleProps {
  firewallData: DeviceListStore["firewall"];
  ruleIndex: number;
  dispatch: React.Dispatch<DeviceListAction>;
}

const NAT1ManyRule: React.FC<NAT1ManyRuleProps> = ({ firewallData, ruleIndex, dispatch }) => {
  const { t } = useTranslation("deviceList");
  const rule = firewallData.rules1ManyNAT.list[ruleIndex];
  const { list, pageCount, total } = getFirewallRules1ManyNATTable(rule);
  const rules11NATColumnsConfig = useFirewallRules1ManyNATTableConfig();

  const handle1ManyNATPerPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_1_MANY_NAT_PER_PAGE", payload: { ruleIndex, perPage: value } }),
    [dispatch, ruleIndex]
  );
  const handle1ManyNATCurrentPageChange = useCallback(
    (value) =>
      dispatch({ type: "SET_FIREWALL_RULES_1_MANY_NAT_CURRENT_PAGE", payload: { ruleIndex, currentPage: value } }),
    [dispatch, ruleIndex]
  );

  return (
    <>
      <div className={styles.naasNATRulesHeader}>
        <div className={styles.naasNATRulesHeaderElement}>
          <span className={styles.naasNATRulesHeaderSubtitle}>{t("deviceListTable.uplink")}:</span>
          <span>{rule.uplink}</span>
        </div>
        <div className={styles.naasNATRulesHeaderElement}>
          <span className={styles.naasNATRulesHeaderSubtitle}>{t("deviceListTable.publicIp")}:</span>
          <span>{rule.publicIp}</span>
        </div>
      </div>
      <div className={styles.naasNATRulesTableHeader}>
        <span className={styles.naasNATRulesHeaderSubtitle}>{t("rules")}:</span>
      </div>
      <div className={styles.naasDeviceTableBlock} data-testid="firewallRules1ManyNATTable">
        <Table dataSource={list} rowKey="networkFirewallRule1ManyNAT" columns={rules11NATColumnsConfig} />
      </div>
      {total > 5 ? (
        <PaginationBox
          perPage={rule.perPage}
          currentPage={rule.currentPage}
          pageCount={pageCount}
          onPerPageChange={handle1ManyNATPerPageChange}
          onChangePage={handle1ManyNATCurrentPageChange}
          steps={SMALL_PAGINATION_STEPS}
        />
      ) : null}
    </>
  );
};

export default React.memo(NAT1ManyRule);
