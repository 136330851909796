import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner, Table, TextInput } from "@skyportal/ui-kit";
import ChartBox from "layouts/ChartBox";
import * as contractApi from "api/organizations";
import { RequestStatus } from "types/common.types";
import { contractSelectors } from "store/contracts";
import { networksSelectors } from "store/networks";
import PaginationBox from "components/PaginationBox";
import CardFallback from "components/CardFallback";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";
import useWirelessDeviceListTableConfig from "../hooks/useWirelessDeviceListTableConfig";
import { DeviceListAction, DeviceListStore } from "../deviceListStore/deviceListStore.types";
import { getWirelessDeviceListTable } from "../deviceListStore/deviceListStore.selectors";

import styles from "../styles.module.css";

export interface WirelessDeviceListProps {
  store: DeviceListStore;
  dispatch: React.Dispatch<DeviceListAction>;
  handleSortFieldChange: (field: any) => void;
  handleCurrentPageChange: (field: any) => void;
}

const WirelessDeviceList: React.FC<WirelessDeviceListProps> = ({
  store,
  dispatch,
  handleSortFieldChange,
  handleCurrentPageChange,
}) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation("deviceList");
  const { list, pageCount, total } = getWirelessDeviceListTable(store);
  const columnsConfig = useWirelessDeviceListTableConfig();

  const networkListRequest = useSelector(networksSelectors.getNetworkListRequestStatus);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  const handleFilterChange = useCallback(
    (event) => dispatch({ type: "SET_WIRELESS_DEVICES_LIST_FILTER", payload: event.target.value }),
    [dispatch]
  );
  const handlePerPageChange = useCallback(
    (value) => dispatch({ type: "SET_WIRELESS_DEVICES_LIST_PER_PAGE", payload: value }),
    [dispatch]
  );

  const reloadData = useCallback(() => {
    if (organizationId && networkId) {
      dispatch({ type: "WIRELESS_DEVICES_REQUEST" });
      contractApi
        .getNetworkWirelessDeviceList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "WIRELESS_DEVICES_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "WIRELESS_DEVICES_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:wirelessDeviceListRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });
    }
  }, [dispatch, networkId, organizationId, reduxDispatch]);

  return (
    <>
      <ChartBox showNoDataPlaceholder={store.wireless.isDataEmpty}>
        {(store.wireless.requestStatus === RequestStatus.PENDING || networkListRequest === RequestStatus.PENDING) && (
          <Spinner show />
        )}
        {store.wireless.requestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.naasDeviceFilterBlock}>
              <TextInput
                value={store.wireless.filter}
                placeholder={t("deviceListFilterPlaceholder")}
                onChange={handleFilterChange}
                data-testid="wirelessDeviceListFilter"
              />
              <span data-testid="wirelessDeviceListCounter">{t("deviceListNumberOfClients", { count: total })}</span>
            </div>
            <div className={styles.naasDeviceTableBlock} data-testid="wirelessDeviceListTable">
              <Table
                dataSource={list}
                rowKey="networkWirelessDevice"
                columns={columnsConfig}
                sortField={store.sortField}
                sortOrder={store.sortOrder}
                onSortFieldChange={handleSortFieldChange}
              />
            </div>
            <PaginationBox
              perPage={store.wireless.perPage}
              currentPage={store.currentPage}
              pageCount={pageCount}
              onPerPageChange={handlePerPageChange}
              onChangePage={handleCurrentPageChange}
            />
          </>
        )}
        {networkListRequest !== RequestStatus.PENDING && store.wireless.requestStatus === RequestStatus.FAILURE && (
          <CardFallback onReload={reloadData} />
        )}
      </ChartBox>
    </>
  );
};

export default React.memo(WirelessDeviceList);
