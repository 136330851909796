// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__HkUdA {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0 calc(var(--padding-small) * -1);\n}\n\n.styles_box__pbshd {\n  display: flex;\n  width: 10%;\n  box-sizing: border-box;\n  flex-grow: 1;\n  flex-shrink: 0;\n  padding: var(--padding-small);\n  margin: var(--padding-small);\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/NetworkDeviceSummary/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,YAAY;EACZ,cAAc;EACd,6BAA6B;EAC7B,4BAA4B;EAC5B,kBAAkB;AACpB","sourcesContent":[".root {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0 calc(var(--padding-small) * -1);\n}\n\n.box {\n  display: flex;\n  width: 10%;\n  box-sizing: border-box;\n  flex-grow: 1;\n  flex-shrink: 0;\n  padding: var(--padding-small);\n  margin: var(--padding-small);\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__HkUdA",
	"box": "styles_box__pbshd"
};
export default ___CSS_LOADER_EXPORT___;
