import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Table } from "@skyportal/ui-kit";
import i18n from "locales";
import * as contractApi from "api/organizations";
import { DeviceListAction, DeviceListStore } from "containers/DeviceList/deviceListStore/deviceListStore.types";
import { networksSelectors } from "store/networks";
import { contractSelectors } from "store/contracts";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import ChartBox from "layouts/ChartBox";
import CardFallback from "components/CardFallback";
import { RequestStatus } from "types/common.types";
import PaginationBox from "components/PaginationBox";
import {
  getFirewallRulesL3InboundTable,
  getFirewallRulesL3OutboundTable,
} from "containers/DeviceList/deviceListStore/deviceListStore.selectors";
import useFirewallRulesL3TableConfig from "containers/DeviceList/hooks/useFirewallRulesL3TableConfig";
import { SMALL_PAGINATION_STEPS } from "utils/table";
import InfoPlaceholder from "components/InfoPlaceholder";

import styles from "../../styles.module.css";

export interface Layer3CardProps {
  firewallData: DeviceListStore["firewall"];
  dispatch: React.Dispatch<DeviceListAction>;
}

const Layer3Card: React.FC<Layer3CardProps> = ({ firewallData, dispatch }) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation("deviceList");
  const {
    list: inboundList,
    pageCount: inboundPageCount,
    total: inboundTotal,
  } = getFirewallRulesL3InboundTable(firewallData);
  const {
    list: outboundList,
    pageCount: outboundPageCount,
    total: outboundTotal,
  } = getFirewallRulesL3OutboundTable(firewallData);
  const columnsConfig = useFirewallRulesL3TableConfig();

  const networkListRequest = useSelector(networksSelectors.getNetworkListRequestStatus);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  const handleInboundPerPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_L3_INBOUND_PER_PAGE", payload: value }),
    [dispatch]
  );
  const handleInboundCurrentPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_L3_INBOUND_CURRENT_PAGE", payload: value }),
    [dispatch]
  );

  const handleOutboundPerPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_L3_OUTBOUND_PER_PAGE", payload: value }),
    [dispatch]
  );
  const handleOutboundCurrentPageChange = useCallback(
    (value) => dispatch({ type: "SET_FIREWALL_RULES_L3_OUTBOUND_CURRENT_PAGE", payload: value }),
    [dispatch]
  );

  const reloadData = useCallback(() => {
    if (organizationId && networkId) {
      dispatch({ type: "FIREWALL_RULES_L3_INBOUND_REQUEST" });
      contractApi
        .getNetworkFirewallRulesL3InboundList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_L3_INBOUND_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_RULES_L3_INBOUND_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallRulesL3InboundRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });

      dispatch({ type: "FIREWALL_RULES_L3_OUTBOUND_REQUEST" });
      contractApi
        .getNetworkFirewallRulesL3OutboundList(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_L3_OUTBOUND_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          dispatch({ type: "FIREWALL_RULES_L3_OUTBOUND_REQUEST_FAILURE" });
          reduxDispatch(
            notificationErrorAC({
              message: i18n.t("notification:firewallRulesL3OutboundRequestFailure"),
              description: parseApiErrorMessage(apiErrorMessage),
            })
          );
        });
    }
  }, [dispatch, networkId, organizationId, reduxDispatch]);

  return (
    <>
      <ChartBox title={t("firewallRulesL3Title")} contentMinHeight={150} data-testid="firewallLayer3RulesCard">
        {(firewallData.rulesL3Inbound.requestStatus === RequestStatus.PENDING ||
          firewallData.rulesL3Outbound.requestStatus === RequestStatus.PENDING ||
          networkListRequest === RequestStatus.PENDING) && <Spinner show />}
        {firewallData.rulesL3Inbound.requestStatus === RequestStatus.SUCCESS &&
          firewallData.rulesL3Outbound.requestStatus === RequestStatus.SUCCESS && (
            <div className={styles.naasRulesContent}>
              <h4 className={styles.naasRulesSubtitle}>{t("inboundRules")}</h4>
              {firewallData.rulesL3Inbound.list.length ? (
                <>
                  <div className={styles.naasDeviceTableBlock} data-testid="firewallRulesL3InboundTable">
                    <Table dataSource={inboundList} rowKey="networkFirewallRuleL3Inboud" columns={columnsConfig} />
                  </div>
                  {inboundTotal > 5 ? (
                    <PaginationBox
                      perPage={firewallData.rulesL3Inbound.perPage}
                      currentPage={firewallData.rulesL3Inbound.currentPage}
                      pageCount={inboundPageCount}
                      onPerPageChange={handleInboundPerPageChange}
                      onChangePage={handleInboundCurrentPageChange}
                      steps={SMALL_PAGINATION_STEPS}
                    />
                  ) : null}
                </>
              ) : (
                <InfoPlaceholder title={t("noRulesFound")} minHeight={50} />
              )}
              <h4 className={styles.naasRulesSubtitle} style={{ marginTop: inboundTotal > 5 ? 0 : 16 }}>
                {t("outboundRules")}
              </h4>
              {firewallData.rulesL3Outbound.list.length ? (
                <>
                  <div className={styles.naasDeviceTableBlock} data-testid="firewallRulesL3OutboundTable">
                    <Table dataSource={outboundList} rowKey="networkFirewallRuleL3Outbound" columns={columnsConfig} />
                  </div>
                  {outboundTotal > 5 ? (
                    <PaginationBox
                      perPage={firewallData.rulesL3Outbound.perPage}
                      currentPage={firewallData.rulesL3Outbound.currentPage}
                      pageCount={outboundPageCount}
                      onPerPageChange={handleOutboundPerPageChange}
                      onChangePage={handleOutboundCurrentPageChange}
                      steps={SMALL_PAGINATION_STEPS}
                    />
                  ) : null}
                </>
              ) : (
                <InfoPlaceholder title={t("noRulesFound")} minHeight={50} />
              )}
            </div>
          )}
        {networkListRequest !== RequestStatus.PENDING &&
          (firewallData.rulesL3Inbound.requestStatus === RequestStatus.FAILURE ||
            firewallData.rulesL3Outbound.requestStatus === RequestStatus.FAILURE) && (
            <CardFallback onReload={reloadData} />
          )}
      </ChartBox>
    </>
  );
};

export default React.memo(Layer3Card);
