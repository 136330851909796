import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Icon, Tooltip } from "@skyportal/ui-kit";
import { ClientConnectionType, ClientStatus, NetworkClientModel } from "api/organizations.types";
import { formatDateAndTime } from "utils/time";
import { convertKilobytes } from "utils/helpers";

import styles from "./styles.module.css";

export const clientStatusStyles = {
  [ClientStatus.Online]: styles.naasClientOnlineBadge,
  [ClientStatus.Offline]: styles.naasClientOfflineBadge,
};

export const clientStatusIcon = {
  [ClientConnectionType.Wireless]: "network-wifi",
  [ClientConnectionType.Wired]: "wired",
};

export const clientStatusOnlineTooltipText = {
  [ClientConnectionType.Wireless]: "onlineWirelessClient",
  [ClientConnectionType.Wired]: "onlineWiredClient",
};

export const clientStatusOfflineTooltipText = {
  [ClientConnectionType.Wireless]: "offlineWirelessClient",
  [ClientConnectionType.Wired]: "offlineWiredClient",
};

const getClientStatusTooltipText = (client: NetworkClientModel) => {
  const { status, connectionType } = client;
  if (status === ClientStatus.Online) {
    return clientStatusOnlineTooltipText[connectionType];
  } else if (status === ClientStatus.Offline) {
    return clientStatusOfflineTooltipText[connectionType];
  }

  return "";
};

const useClientListTableConfig = () => {
  const { t } = useTranslation("clientList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "status",
        dataIndex: "status",
        title: t("clientListTable.status"),
        render: (status: NetworkClientModel["status"], client: NetworkClientModel) =>
          status && client.connectionType ? (
            <Tooltip title={t(getClientStatusTooltipText(client))}>
              <div className={cn(styles.naasClientStatusBadge, clientStatusStyles[status])}>
                <Icon icon={clientStatusIcon[client.connectionType]} />
              </div>
            </Tooltip>
          ) : null,
      },
      {
        key: "description",
        dataIndex: "description",
        title: t("clientListTable.description"),
      },
      {
        key: "lastSeen",
        dataIndex: "lastSeen",
        title: t("clientListTable.lastSeen"),
        render: (lastSeen: NetworkClientModel["lastSeen"]) => formatDateAndTime(lastSeen),
      },
      {
        key: "usage",
        dataIndex: "usage",
        title: t("clientListTable.usage"),
        render: (usage: NetworkClientModel["usage"]) => (
          <>
            <span className={styles.naasClientUsageText}>{convertKilobytes(usage.total)}</span>{" "}
            <span className={styles.naasClientUsageText}>
              {"("}
              <Icon icon="arrow-down" height={12} />
              {convertKilobytes(usage.received)}{" "}
            </span>
            <span className={styles.naasClientUsageText}>
              <Icon icon="arrow-up" height={12} />
              {convertKilobytes(usage.sent)}
              {")"}
            </span>
          </>
        ),
      },
      {
        key: "clientType",
        dataIndex: "clientType",
        title: t("clientListTable.clientType"),
      },
      {
        key: "macAddress",
        dataIndex: "macAddress",
        title: t("clientListTable.macAddress"),
      },
      {
        key: "ipv4",
        dataIndex: "ipv4",
        title: t("clientListTable.ipv4"),
      },
      {
        key: "ipv6",
        dataIndex: "ipv6",
        title: t("clientListTable.ipv6"),
      },
      {
        key: "port",
        dataIndex: "port",
        title: t("clientListTable.port"),
      },
      {
        key: "connectedTo",
        dataIndex: "connectedTo",
        title: t("clientListTable.connectedTo"),
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useClientListTableConfig;
