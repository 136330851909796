export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

const Network: BreadcrumbsItem = {
  label: "Network",
  path: "/naas",
  dustinIcon: "network-wifi-2",
};

const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
  {
    label: Network.label,
    dustinIcon: Network.dustinIcon,
    match: {
      path: Network.path,
      exact: true,
    },
  },
  {
    label: Network.label,
    dustinIcon: Network.dustinIcon,
    match: {
      path: "/naas/dashboard",
    },
  },
  {
    label: "Clients",
    crumbs: [Network],
    dustinIcon: "computer",
    match: {
      path: "/naas/clients",
    },
  },
  {
    label: "Devices",
    crumbs: [Network],
    dustinIcon: "network",
    match: {
      path: "/naas/devices",
    },
  },
];

export default breadcrumbsConfig;
