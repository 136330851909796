import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles.module.css";

interface InfoPlaceholderProps {
  title?: string;
  minHeight?: number;
}

const InfoPlaceholder: React.FC<InfoPlaceholderProps> = ({ title, minHeight = 350 }) => {
  const { t } = useTranslation("naas");

  return (
    <div className={styles.naasInfoPlaceholderRoot} style={{ minHeight }} data-testid="dataPlaceholder">
      <Icon icon="search" height={20} />
      {title || t("No data found")}
    </div>
  );
};

export default InfoPlaceholder;
