// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_naasCard__ASfZc {\n  overflow: hidden;\n  width: 100%;\n  min-width: 200px;\n  flex-grow: 1;\n  margin-top: 16px;\n}\n\n.styles_naasCard__ASfZc h3 {\n  padding: 8px 16px !important;\n  font-weight: 700 !important;\n}\n\n.styles_naasCardContent__3Z4Jk {\n  position: relative;\n  overflow: auto;\n  min-height: 350px;\n}\n", "",{"version":3,"sources":["webpack://./src/layouts/ChartBox/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".naasCard {\n  overflow: hidden;\n  width: 100%;\n  min-width: 200px;\n  flex-grow: 1;\n  margin-top: 16px;\n}\n\n.naasCard h3 {\n  padding: 8px 16px !important;\n  font-weight: 700 !important;\n}\n\n.naasCardContent {\n  position: relative;\n  overflow: auto;\n  min-height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"naasCard": "styles_naasCard__ASfZc",
	"naasCardContent": "styles_naasCardContent__3Z4Jk"
};
export default ___CSS_LOADER_EXPORT___;
