import React from "react";
import { Card } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import NetworkListItem from "containers/NetworkListItem";
import { networksSelectors } from "store/networks";
import { contractSelectors } from "store/contracts";
import { RequestStatus } from "types/common.types";
import FilterNetworkListBar from "../FilterNetworkListBar";
import { NetworkListItemModel } from "store/networks/netwoks.types";
import InfoPlaceholder from "components/InfoPlaceholder";

import styles from "./styles.module.css";

export interface NetworkListProps {}

const NetworkList: React.FC<NetworkListProps> = () => {
  const { t } = useTranslation("naas");
  const networkList = useSelector(networksSelectors.getNetworkList);
  const networkListFiltered = useSelector(networksSelectors.getFilteredNetworkList);
  const networkListRequestStatus = useSelector(networksSelectors.getNetworkListRequestStatus);
  const contractListRequestStatus = useSelector(contractSelectors.getContractListRequestStatus);
  const selectedNetworkId = useSelector(networksSelectors.getSelectedNetworkId);

  const selectedNetwork = (networkList as NetworkListItemModel[]).find((network) => network.id === selectedNetworkId);

  return (
    <>
      <Card
        data-test-id="NetworkList"
        padding="medium"
        title={selectedNetwork ? selectedNetwork.name : null}
        loading={
          networkListRequestStatus === RequestStatus.PENDING ||
          contractListRequestStatus === RequestStatus.PENDING ||
          networkListRequestStatus === RequestStatus.UNCALLED ||
          contractListRequestStatus === RequestStatus.UNCALLED
        }
        className={cn(styles.naasCard, { [styles.naasCardSelected]: selectedNetwork })}
      >
        <FilterNetworkListBar />
        {networkListFiltered.length ? (
          <>
            <ul style={{ listStyle: "none", margin: 0 }}>
              {networkListFiltered.map((item) => (
                <NetworkListItem key={item.id} source={item} />
              ))}
            </ul>
          </>
        ) : null}
        {networkListRequestStatus === RequestStatus.SUCCESS && !networkListFiltered.length ? (
          <InfoPlaceholder title={t("No networks found")} minHeight={250} />
        ) : null}
      </Card>
    </>
  );
};

export default NetworkList;
