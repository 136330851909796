import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Spinner } from "@skyportal/ui-kit";
import ChartBox from "layouts/ChartBox";
import { RequestStatus } from "types/common.types";
import { useWirelessStatisticFetch } from "./useWirelessStatisticFetch";
import { composeBySsidChartOptions, composeByBandChartOptions } from "./utils";

import styles from "./styles.module.css";

export interface WirelessStatisticsProps {}

const WirelessStatistics: React.FC<WirelessStatisticsProps> = () => {
  const { t } = useTranslation("naas");
  const { ssids, requestStatus, bands, isDataEmpty } = useWirelessStatisticFetch();

  const bySsidsChartOptions = useMemo(() => composeBySsidChartOptions(ssids), [ssids]);
  const byBandsChartOptions = useMemo(() => composeByBandChartOptions(bands), [bands]);

  return (
    <>
      <h2 className={styles.naasSubtitle}>
        {t("Wireless statistics")} <span className={styles.naasSubtitleSmallText}>{t("last 24 hours")}</span>
      </h2>
      <div className={styles.root}>
        <ChartBox title={t("SSID")} showNoDataPlaceholder={isDataEmpty || requestStatus === RequestStatus.FAILURE}>
          <div data-testid="PieChartBySsids">
            {requestStatus === RequestStatus.PENDING && <Spinner show />}
            {requestStatus === RequestStatus.SUCCESS && (
              <HighchartsReact highcharts={Highcharts} options={bySsidsChartOptions} />
            )}
          </div>
        </ChartBox>
        <ChartBox title={t("Band")} showNoDataPlaceholder={isDataEmpty || requestStatus === RequestStatus.FAILURE}>
          <div data-testid="PieChartByBands">
            {requestStatus === RequestStatus.PENDING && <Spinner show />}
            {requestStatus === RequestStatus.SUCCESS && (
              <HighchartsReact highcharts={Highcharts} options={byBandsChartOptions} />
            )}
          </div>
        </ChartBox>
      </div>
    </>
  );
};

export default React.memo(WirelessStatistics);
